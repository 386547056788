import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { Store, select } from '@ngrx/store';
import { activeLanguage } from './auth/state/auth/auth.selectors';

import { IonApp, IonRouterOutlet } from "@ionic/angular/standalone";
import { UpdateService } from './update-service';
import { NavigationEnd, Router } from '@angular/router';
import { init } from "@driplane/web";
import Logger from './logger.service';

const log = Logger('component:app');

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [IonApp, IonRouterOutlet],
})
export class AppComponent {

  stage = environment.stage;

  constructor(private store: Store, private router: Router, private translate: TranslateService, private updateService: UpdateService) {
    translate.setDefaultLang('en');

    const { trackPageview } = init({ token: 'IhRUVTwG3vgsZhAEXzvPaYz3q2uX3Zl7vQ2anRsmA0Y', modules: ['vitals'] });

    this.store.pipe(
      select(activeLanguage),
    ).subscribe(async (language) => {
      let translation;

      if (language === 'tr') {
        translation = await import(`./translations/tr`);
      }
      if (language === 'nl') {
        translation = await import(`./translations/nl`);
      }
      if (language === 'en') {
        translation = await import(`./translations/en`);
      }

      translate.setTranslation(language, translation.default);
      translate.use(language);
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        log('Navigation end:', event);
        trackPageview()
      }
    });
  }
}
