import { ApplicationRef, Injectable } from "@angular/core";
import { SwUpdate } from "@angular/service-worker";
import { Store } from "@ngrx/store";
import * as Sentry from "@sentry/angular";

import { addIcons } from "ionicons";
import { cloudDownloadOutline } from "ionicons/icons";
import { showToast } from "./auth/state/toast/toast.actions";
import { concat, first, interval } from "rxjs";
import Logger from './logger.service';

const log = Logger('service:update-service');

@Injectable({providedIn: 'root'})
export class UpdateService {
  constructor(private store: Store, updates: SwUpdate, appRef: ApplicationRef) {
    addIcons({ cloudDownloadOutline });

    log('Update Service initialized');
    updates.versionUpdates.subscribe((evt) => {
      switch (evt.type) {
        case 'VERSION_DETECTED':
          log(`Downloading new app version: ${evt.version.hash}`);
          break;
        case 'VERSION_READY':
          log(`Current app version: ${evt.currentVersion.hash}`);
          log(`New app version ready for use: ${evt.latestVersion.hash}`);
          this.showUpdateToast();
          break;
        case 'VERSION_INSTALLATION_FAILED':
          log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
          Sentry.captureException(evt);
          break;
      }
    });

    // Periodic check for updates
    const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));
    const everyHour$ = interval(60 * 60 * 1000); // check every hour
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everyHour$);
    everySixHoursOnceAppIsStable$.subscribe(async () => {
      try {
        const updateFound = await updates.checkForUpdate();
        log(updateFound ? 'A new version is available.' : 'Already on the latest version.');
      } catch (err) {
        console.error('Failed to check for updates:', err);
      }
    });

  }

  showUpdateToast() {
    this.store.dispatch(showToast({
      message: 'New version of panel is available.',
      icon: 'cloud-download-outline',
      duration: 0,
      color: 'primary',
      translucent: true,
      buttons: [{
        text: 'Update',
        handler: () => {
          document.location.reload();
        }
      }, {
        text: 'Later',
        role: 'cancel',
      }]
    }));
  }
}
