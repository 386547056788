import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { ErrorResponse } from './synaps-media.types';

export const httpInterceptor: HttpInterceptorFn = (req, next) => {
  return next(req.clone({
    withCredentials: true
  })).pipe(
    catchError((error: HttpErrorResponse) => {
      if (error.error?.errors) {
        return throwError(() => (error.error as ErrorResponse));
      }
      return throwError(() => ({ errors: [{ code: 'unknown_error', message: 'An unknown error occurred' }] }));
    }),
  );
};
